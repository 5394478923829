import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DefaultResponse } from './domains/calculator.domain';
import { jwtDecode } from 'jwt-decode';
import { Roles, RolePermissions } from './domains/auth.domain';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userRoles: Roles[] = [];

  isMigratedUser: boolean = false;

  constructor(private readonly httpC: HttpClient) { }

  isLogged() {
    // get and parse JWT
    const jwt = localStorage.getItem('jwt');

    if (!jwt || jwt === 'undefined') {
      return false;
    }

    //decode token
    const tokenDecoded = jwtDecode(jwt); //token decoded
    const tokenExp = tokenDecoded.exp //token expiration date
    const now = new Date();
    const expDate = new Date(0);
    expDate.setUTCSeconds(tokenExp!);
    if (now > expDate) {
      return false;
    }
    return true;
  }

  goToSSO() {
    window.location.href = `${environment.ssoLocation}#${encodeURIComponent(window.location.host)}`;
  }

  getJWT() {
    return localStorage.getItem('jwt');
  }

  _processURI() {
    if (window.location.hash) {
      this._setSession(window.location.hash.substring(1));
      window.location.hash = '';
      window.location.reload();
    }
  }

  _setSession(base64: string) {
    //Se aplico Jwt Decode pero sigue siendo necesario decodificar el base64 con atob() y luego decodificar el token que esta adentro
    const base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
    try {
      const data = JSON.parse(atob(base64String));
      // SET JWT:
      localStorage.setItem('data', JSON.stringify(data));
      localStorage.setItem('jwt', data.jwt);
    } catch (error) {
      alert(`Código de error: #${base64}`);
    }
  }

  setIsMigrated() {

  }

  refreshAccessToken(): Observable<any> {
    // Lógica para renovar el token, puede ser una solicitud HTTP a tu backend
    // Supongamos que esta función devuelve un observable con el nuevo token
    return this.httpC.post('URL_para_refrescar_token', {}).pipe(
      tap((response: any) => {
        // Actualizar el token almacenado en tu aplicación con el nuevo token
        //this.tokenService.setToken(response.token);
      })
    );
  }

  getClientById(id: number): Observable<any> {
    const url = `${environment.api}client/${id}`;
    return this.httpC.get<any>(url);
  }

  setUserRoles() {
    const jwt = localStorage.getItem('jwt');
    if (!jwt || jwt === 'undefined') {
      return;
    }

    //decode token
    const tokenDecoded = jwtDecode(jwt); //token decoded
    this.userRoles = (tokenDecoded as any).rol;
  }

  getUserRoles() {
    return this.userRoles;
  }

  getIsMigratedUser() {
    return JSON.parse(localStorage.getItem('data')!).migrated;
  }

  autoLogin(): Observable<DefaultResponse<any>> {
    return this.httpC.post<DefaultResponse<any>>(`${environment.api}user/login/ipuma`, {}, {
      withCredentials: true
    });
  }

  // refresherJwt(): Observable<any>  {
  //   const jwt = localStorage.getItem('jwt');
  //   if (jwt !== undefined && jwt !== null) {
  //     return this.httpC.post<any>(`${environment.apiSSO}auth/refresher`, {jwt: jwt});
  //   } else {
  //     return new Observable(undefined);
  //   }
  // }

  isCorporatedUser() {
    return this.userRoles.includes(Roles.CORPORATE)
  }

  isVerificatorUser() {
    return this.userRoles.includes(Roles.VERIFICATOR)
  }

  hasPermission(userRoles: Roles[], permission: keyof typeof RolePermissions[Roles]): boolean {
    // Verifica si todos los roles del usuario permiten el permiso.
    return userRoles.every(role => RolePermissions[role]?.[permission] !== false);
  }  

  canDownload(): boolean {
    return this.hasPermission(this.userRoles, 'canDownload');
  }

  canViewCalculations(): boolean {
    return this.hasPermission(this.userRoles, 'canViewCalculations');
  }

  canExportReports(): boolean {
    return this.hasPermission(this.userRoles, 'canExportReports');
  }

  canUseCalculator(): boolean {
    return this.hasPermission(this.userRoles, 'canUseCalculator');
  }

  canManageModels(): boolean {
    return this.hasPermission(this.userRoles, 'canManageModels');
  }

  canModifyFields(): boolean {
    return this.hasPermission(this.userRoles, 'canModifyFields');
  }

  canImportData(): boolean {
    return this.hasPermission(this.userRoles, 'canImportData');
  }

  canExportResults(): boolean {
    return this.hasPermission(this.userRoles, 'canExportResults');
  }

}
