export interface LoginIn {
  user: string
  password: string
}

export interface CustomResponse<T> {
  total: number;
  methodEnabled: number;
  lenght: number;
  rol: string;
  expiration: any;
  operationSuccess: boolean;
  description: string;
  code: number;
  payload: T;
}

export class TokenData {
  token?: string;
  expiration?: number;
  rol?: string;
}

export enum Role {
  NO_ROLE = 'NO_ROLE',
  ROLE_SUPER_USER = 'ROLE_SUPER_USER',
  ROLE_CLIENT = 'ROLE_CLIENT'
}

export enum Roles {
  ADMIN = 'ROLE_ADMIN',
  CLIENT = 'ROLE_CLIENT',
  PRODUCER = 'ROLE_PRODUCER',
  CORPORATE = 'ROLE_CORPORATE',
  VERIFICATOR = 'ROLE_VERIFICATOR',
  VISUALIZATION = 'PERMISSION_VISUALIZATION',
  NOT_VISUALIZATION = 'PERMISSION_NOT_VISUALIZATION',
}

export const RolePermissions = {
  [Roles.ADMIN]: {
    canDownload: true,
    canViewCalculations: true,
    canExportReports: true,
    canUseCalculator: true,
    canManageModels: true,
    canModifyFields: true,
    canImportData: true,
    canExportResults: true
  },
  [Roles.CLIENT]: {
    canDownload: true,
    canViewCalculations: true,
    canExportReports: true,
    canUseCalculator: true,
    canManageModels: true,
    canModifyFields: true,
    canImportData: true,
    canExportResults: true
  },
  [Roles.PRODUCER]: {
    canDownload: true,
    canViewCalculations: true,
    canExportReports: true,
    canUseCalculator: true,
    canManageModels: true,
    canModifyFields: true,
    canImportData: true,
    canExportResults: true
  },
  [Roles.CORPORATE]: {
    canDownload: true,
    canViewCalculations: false,
    canExportReports: false,
    canUseCalculator: false,
    canManageModels: false,
    canModifyFields: false,
    canImportData: false,
    canExportResults: true
  },
  [Roles.VERIFICATOR]: {
    canDownload: true,
    canViewCalculations: true,
    canExportReports: false,
    canUseCalculator: false,
    canManageModels: false,
    canModifyFields: false,
    canImportData: false,
    canExportResults: true
  },
  [Roles.VISUALIZATION]: {
    canDownload: false,
    canViewCalculations: true,
    canExportReports: false,
    canUseCalculator: false,
    canManageModels: false,
    canModifyFields: false,
    canImportData: false,
    canExportResults: true
  },
  [Roles.NOT_VISUALIZATION]: {
    canDownload: false,
    canViewCalculations: false,
    canExportReports: false,
    canUseCalculator: false,
    canManageModels: false,
    canModifyFields: false,
    canImportData: false,
    canExportResults: true
  }
};
